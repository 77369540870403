<template>
  <div>
    <!--begin::DocumentVQA-->
    <div class="row">
      <div class="col-lg-8 col-xxl-8">
        <ListWidget3></ListWidget3>
      </div>

      <div class="col-xxl-4">
        <StatWidget7></StatWidget7>
        <Paper></Paper>
      </div>
    </div>
    <!--end::DocumentVQA-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import StatWidget7 from "@/view/content/widgets/stats/Widget12.vue";
import Paper from "@/view/content/widgets/due/Paper.vue";

export default {
  name: "figure-qa",
  components: {
    ListWidget3,
    StatWidget7,
    Paper
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Leaderboard" },
      { title: "Figure QA" }
    ]);
  }
};
</script>
